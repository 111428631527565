// React
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// Router Utils and Redux
import PrivateRoute from './utils/privateRoute';
import { history } from './store/store';

// Components
import Login from './pages/login/login';
import Callback from './pages/login/callback';
import Home from './pages/home/home';
import LoggedOut from './pages/home/auth/loggedOut';

/**
 * For storing internal page redirection inside application
 */
export const Routes = () => {
     return (
          <BrowserRouter history={history} >
               <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/callback" component={Callback} />
                    <Route exact path="/auth/logged-out/:authState" component={LoggedOut} />
                    <PrivateRoute path="/home" component={Home} />
                    <Route component={Login} />
               </Switch>
          </BrowserRouter>
     );
};
