// React
import React from "react";

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Material
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Skeleton from '@material-ui/lab/Skeleton';

// Form Wrappers
import { TextFieldWrapper } from "../../../components/form/textField";
import { Validations } from "../../../components/form/validations";

import PhotoEditor from "./photo-editor/photoEditor";
import { FormattedMessage } from "react-intl";
import FieldSkeleton from "../../../components/skeleton/FieldSkeleton";
import { injectIntl } from 'react-intl';

/**
 * @class Basics
 * @description component contains Basic Section
 */
class Basics extends React.PureComponent {

     /**
      * @function constructor
      * @param {*} props - Having properties passed by parent element
      *  * used for state initialization
      */
     constructor(props) {
          super(props);
          this.state = {
               isPhotoValid: true,
               isPhotoLoaded: false,
               userSelectedPhoto: null
          };
     }
     selectedPhotoRef;

     UNSAFE_componentWillMount() {
          if (this.props.picture) {
               this.loadPhoto(this.props.picture);
          }
     }
     UNSAFE_componentWillReceiveProps(newProps) {
          if (newProps.picture && newProps.picture !== this.props.picture) {
               this.loadPhoto(newProps.picture);
          }
     }

     loadPhoto = (link) => {
          if(link !== "NO_PHOTO") {
               let image = new Image();
               image.onload = () => {
                    this.setState({ isPhotoLoaded: true });
               };
               image.src = link;
          }          
     }

     openPhotoEditor = (event) => {
          this.setState({ userSelectedPhoto: URL.createObjectURL(event.target.files[0]) });
          this.selectedPhotoRef.value = "";
     };

     clearPhoto = () => {
          this.setState({ userSelectedPhoto: null });
     };

     /**
      * @function openFileFinder
      * @description to get File details if user clicks image Edit option
      */
     openFileFinder = () => {
          this.selectedPhotoRef.click();
     }

     /**
      * @function render
      * @description repaints the component on each change of state and props value
      */
     render() {

          let photo;
          let firstName;
          let lastName;

          if (this.props.isLoaded || (this.props.picture && this.state.isPhotoLoaded)) {
               let image;
               if (this.props.picture === 'NO_PHOTO' || !this.state.isPhotoValid) {
                    image = (<PersonOutlineIcon className="personIcon" />);
               } else {
                    image = (<img src={this.props.picture} loading="lazy" onError={this.onUserImagNotFound} alt="user" />);
               }
               photo = (
                    <div className="photo-block" onClick={this.openFileFinder}>
                         {image}
                         <EditRoundedIcon className="edit-button" />
                         <input
                              type="file"
                              className="fileUploader off"
                              ref={input => this.selectedPhotoRef = input}
                              onChange={this.openPhotoEditor} />
                    </div>
               );
          } else {
               photo = (
                    <Skeleton animation="wave" className="photo-block" variant="circle" width={104} height={104} />
               );
          }

          if (this.props.firstName) {
               firstName = (
                    <TextFieldWrapper
                         id="firstName"
                         label={<FormattedMessage defaultMessage="First Name" />}
                         initialValue={this.props.firstName}
                         placeHolder={this.props.intl.formatMessage({
                              defaultMessage: "First Name"
                         })}
                         validations={{
                              [Validations.required]: {
                                   [Validations.message]: this.props.intl.formatMessage({
                                        defaultMessage: "First Name is required"
                                   })
                              },
                              [Validations.noSpace]: {
                                   [Validations.message]: this.props.intl.formatMessage({
                                        defaultMessage: "First Name is required"
                                   })
                              }
                         }}
                    />
               );
          } else {
               firstName = <FieldSkeleton fieldWidth={36} />;
          }

          if (this.props.lastName) {
               lastName = (
                    <TextFieldWrapper
                         id="lastName"
                         label={<FormattedMessage defaultMessage="Last Name" />}
                         initialValue={this.props.lastName}
                         placeHolder={this.props.intl.formatMessage({
                              defaultMessage: "Last Name"
                         })}
                         validations={{
                              [Validations.required]: {
                                   [Validations.message]: this.props.intl.formatMessage({
                                        defaultMessage: "Last Name is required"
                                   })
                              },
                              [Validations.noSpace]: {
                                   [Validations.message]: this.props.intl.formatMessage({
                                        defaultMessage: "Last Name is required"
                                   })
                              }
                         }}
                    />
               );
          } else {
               lastName = <FieldSkeleton fieldWidth={36} />
          }

          return (
               <div className="card basics">
                    <h3>
                         <FormattedMessage defaultMessage="Basic Information" />
                    </h3>
                    <div className="form-holder">
                         {photo}
                         <div className="name-holder">
                              {firstName}
                              {lastName}
                         </div>
                    </div>
                    <PhotoEditor
                         photo={this.state.userSelectedPhoto}
                         onClose={this.clearPhoto} />
               </div>
          );
     }

     /**
      * @function onUserImagNotFound
      * @description When photo url not exists / having errorenous file this function loads icon
      */
     onUserImagNotFound = (event) => {
          this.setState({ isPhotoValid: false, isPhotoLoaded: true });
     }

}

/**
 * For mapping necessary state variables to current component properties
 * @param {Object} state - Immutable State Object
 * @return properties needed from state 
 */
const mapStateToProps = (state) => {
     return {
          firstName: state.userManager?.firstName,
          lastName: state.userManager?.lastName,
          picture: state.userManager?.picture || "NO_PHOTO",
          isLoaded: state.userManager?.isLoaded ?? false
     }
};

export default withRouter(connect(mapStateToProps)(injectIntl(Basics)));
