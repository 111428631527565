import authenticationManager from "../utils/authenticationManager";

/**
 * @class AuthService
 * @description For Identity Access Management
 */
class AuthService {

     static signInRedirect = (props) => {
          const queryParams = new URLSearchParams(window.location.search);
          var state_param = queryParams.get("state") && window.atob(queryParams.get("state"));
          var auth_workflow = queryParams.get("auth_workflow") || (state_param && state_param.split('&')[0].split('=')[1]);
          var account = queryParams.get("account") || (state_param && state_param.split('&')[1].split('=')[1]);
          var login_hint = queryParams.get("login_hint") || (state_param && state_param.split('&')[2].split('=')[1]);
     
          var parameters = {
               extraQueryParams: {}
          }
          if(auth_workflow){
               parameters.extraQueryParams.auth_workflow = auth_workflow;
               if(account){
                    parameters.extraQueryParams.account = account;
                    if(login_hint){
                         parameters.extraQueryParams.login_hint = login_hint;
                    }
               }      
          }
          authenticationManager.signinRedirect(parameters);
     };

     static isAuthenticated = (props) => {
          return (props && props.auth && props.auth.access_token) ? true : false;
     }
     static getAccessToken() {
          return localStorage.getItem('access_token');
     }
}

export default AuthService;