import axios from 'axios';

/**
 * @class UserProfileAPI
 * @description For API Detail reference and replication
 *  * setting basic headers for all APIs
 */
export class UserProfileAPI {
     static get_my_details = "/users/me";
     static update_user = "/users/{userId}";
     static get_my_photo = "/users/{userId}/picture";

     /**
      * @function parseRequestURL
      * @description to replace the valu to be passed to above string variables
      * @example /user/{trnId}/create - /user/trn:123-456/create
      * @param {*} requestURL 
      * @param {*} request 
      */
     static parseRequestURL = (requestURL, request) => {
          Object.keys(request).forEach(key => {
               requestURL = requestURL.replace(new RegExp('{' + key + '}', 'g'), request[key]);
          });
          return requestURL;
     }
}

const ProfileService = axios.create();
ProfileService.defaults.baseURL = process.env.REACT_APP_USER_PROFILE_API_URL;
ProfileService.defaults.headers.post['Content-Type'] = 'application/json';

ProfileService.setToken = (accessToken) => {
     ProfileService.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
};

export default ProfileService;