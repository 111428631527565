// React
import React from 'react';
import { makeStyles, createStyles, Container, Typography, Button } from '@material-ui/core';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from "react-redux";
import Header from '../../../components/header/header';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FormattedMessage } from "react-intl";
import { useCallback } from 'react';

const useStyles = makeStyles((theme) =>
     createStyles({
          root: {
               padding: theme.spacing(3)
          },
          alertTitle: {
               fontSize: theme.typography.h6.fontSize,
               fontWeight: 600,
               position: "relative",
               top: "-2px"
          },
          action: {
               fontWeight: 600,
               position: "relative",
               right: "8px"
          }
     }),
);

const LoggedOutMessage = (props) => {
     const styles = useStyles();

     switch (props.state) {
          case "sign-in-failed":
               return (
                    <>
                         <AlertTitle className={styles.alertTitle}>                            
                              <FormattedMessage defaultMessage="Login Failed" />
                         </AlertTitle>
                         <Typography variant="subtitle1">
                              <FormattedMessage defaultMessage="An error occurred while signing into the application." />
                         </Typography>
                    </>
               )

          case "authentication-error":
               return (
                    <>
                         <AlertTitle className={styles.alertTitle}>
                              <FormattedMessage defaultMessage="Authentication Error" />
                         </AlertTitle>
                         <Typography variant="subtitle1">
                              <FormattedMessage defaultMessage="Unable to authenticate your identity with this application." />
                         </Typography>
                    </>
               )

          default:
               return (
                    <>
                         <AlertTitle className={styles.alertTitle}>                             
                              <FormattedMessage defaultMessage="Unknown Error" />
                         </AlertTitle>
                         <Typography variant="subtitle1">
                              <FormattedMessage defaultMessage="An unknown error occurred while signing into the application." />
                         </Typography>
                    </>
               )
     }
}

const LoggedOut = (props) => {
     const params = useParams();
     const styles = useStyles();

     const tryAgainHandler = useCallback(() => {
          if (props?.dispatch) {
               props.dispatch(props.history.push("/home"));
          }

     }, [props]);

     return (
          <>
               <Header />
               <Container className={styles.root} maxWidth={"sm"} >
                    <Alert severity="error" action={
                         <Button onClick={tryAgainHandler} className={styles.action} disableElevation color="inherit" variant="outlined">                              
                              <FormattedMessage defaultMessage="TRY AGAIN" />
                         </Button>
                    }>

                         <LoggedOutMessage state={params.authState} />
                    </Alert>
               </Container>
          </>
     )
};

const mapStateToProps = (state) => {
     return {

     }
};
function mapDispatchToProps(dispatch) {
     return {
          dispatch
     };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoggedOut));
