
import * as actions from "../actions/actions";
import { updateObject } from "../../utils";

/**
 *  Mapping set of Reducers for each Authentication Actions
 * @param {Object} action - Action from action creator
 * @param {Object} state - Default application state
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action = {}) => {
     switch (action.type) {
          case actions.storeBasics: return storeBasics(state, action);
          default: return state;
     }
};


/**
 * To Update Breadcrumb value on change of links
 * @param {Object} state - auth state
 * @param {Object} action 
 * @return {Object} New state
 */
const storeBasics = (state, action) => {
     state[action.payload.id] = action.payload.value

     return updateObject(state);
}


const INITIAL_STATE = {
     countries: null,
     languages: null,
     timeZones: null
};

