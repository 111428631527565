import { combineReducers } from "redux";
import { routerReducer } from 'react-router-redux';
import authenticator from "./authenticator";
import userManager from "./userManager";
import common from "./common";
// Combining all reducers to single root reducer
const rootReducer = combineReducers({
     auth: authenticator,
     routing: routerReducer,
     userManager: userManager,
     common: common
});

export default rootReducer;