// React
import React from 'react';

// Redux
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import * as Oidc from "redux-oidc";

// Utils
import authenticationManager from '../../utils/authenticationManager';
import Loader from '../../components/loader';

// Service
import AuthService from '../../services/authService';

function inIframe() {
     try {
          return window.self !== window.top;
     } catch (e) {
          return true;
     }
}

/**
 * @class Callback
 * @description Identity will redirect the successful login here.
 */
class Callback extends React.Component {

     /**
      * @function constructor
      * @param {*} props - Having properties passed by parent element
      *  * used for state initialization
      */
     constructor(props) {
          super(props);
          this.state = {}
     }

     /**
      * @function render
      * @description repaints the component on each change of state and props value
      */
     render() {
          if (inIframe()) {
               Oidc.processSilentRenew();

               return (
                    <></>
               )
          }

          return (
               <Oidc.CallbackComponent userManager={authenticationManager}
                    successCallback={
                         (success) => {
                              this.props.dispatch(this.props.history.push("/home"));
                         }
                    }
                    errorCallback={
                         (error) => {
                              this.props.dispatch(this.props.history.push("/auth/logged-out/sign-in-failed"));
                         }
                    }
               >
                    <Loader showBackdrop />
               </Oidc.CallbackComponent>

          );

     }
}


/**
 * For mapping necessary state variables to current component properties
 * @param {Object} state - Immutable State Object
 * @return properties needed from state 
 */
const mapStateToProps = (state) => {
     return {
          isAuthenticated: AuthService.isAuthenticated(state),
          state
     }
};
function mapDispatchToProps(dispatch) {
     return {
          dispatch
     };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Callback));