const baseConfig = [{
     locales: ["bg", "bg-BG"],
     source: "bg",
     intl: {
          localeData: "bg"
     }
}, {
     locales: ["cs", "cs-CZ"],
     source: "cs",
     intl: {
          localeData: "cs"
     }
}, {
     locales: ["da", "da-DK"],
     source: "da",
     intl: {
          localeData: "da"
     }
}, {
     locales: ["de", "de-DE"],
     source: "de",
     intl: {
          localeData: "de"
     }
}, {
     locales: ["el", "el-GR"],
     source: "el",
     intl: {
          localeData: "el"
     }
}, {
     locales: ["es-AR"],
     source: "es-AR",
     intl: {
          localeData: "es-AR"
     }
}, {
     locales: ["es", "es-ES"],
     source: "es-ES",
     intl: {
          localeData: "es-ES"
     }
}, {
     locales: ["fi", "fi-FI"],
     source: "fi",
     intl: {
          localeData: "fi"
     }
}, {
     locales: ["fr", "fr-FR"],
     source: "fr",
     intl: {
          localeData: "fr"
     }
}, {
     locales: ["hu", "hu-HU"],
     source: "hu",
     intl: {
          localeData: "hu"
     }
}, {
     locales: ["it", "it-IT"],
     source: "it",
     intl: {
          localeData: "it"
     }
}, {
     locales: ["ja", "ja-JP"],
     source: "ja",
     intl: {
          localeData: "ja"
     }
}, {
     locales: ["ko", "ko-KR"],
     source: "ko",
     intl: {
          localeData: "ko"
     }
}, {
     locales: ["nb", "nb-NO"],
     source: "nb",
     intl: {
          localeData: "nb"
     }
}, {
     locales: ["nl", "nl-NL"],
     source: "nl",
     intl: {
          localeData: "nl"
     }
}, {
     locales: ["pl", "pl-PL"],
     source: "pl",
     intl: {
          localeData: "pl"
     }
}, {
     locales: ["pt-BR"],
     source: "pt-BR",
     intl: {
          localeData: "pt-BR"
     }
}, {
     locales: ["pt", "pt-PT"],
     source: "pt-PT",
     intl: {
          localeData: "pt-PT"
     }
}, {
     locales: ["ro", "ro-RO"],
     source: "ro",
     intl: {
          localeData: "ro"
     }
}, {
     locales: ["ru", "ru-RU"],
     source: "ru",
     intl: {
          localeData: "ru"
     }
}, {
     locales: ["sl", "sl-SI"],
     source: "sl",
     intl: {
          localeData: "sl"
     }
}, {
     locales: ["sv", "sv-SE"],
     source: "sv",
     intl: {
          localeData: "sv"
     }
}, {
     locales: ["th", "th-TH"],
     source: "th",
     intl: {
          localeData: "th"
     }
}, {
     locales: ["tr", "tr-TR"],
     source: "tr",
     intl: {
          localeData: "tr"
     }
}, {
     locales: ["zh-CN"],
     source: "zh-CN",
     intl: {
          localeData: "zh-CN"
     }
}, {
     locales: ["zh-TW"],
     source: "zh-TW",
     intl: {
          localeData: "zh-TW"
     }
}];

const localeConfigs = [{
     locales: ['en', 'en-US', 'en-GB'],
     source: 'en',
     intl: {
          localeData: "en"
     }
}, {
     locales: ['ta'],
     source: 'ta',
     intl: {
          localeData: "en"
     }
}, ...baseConfig];

export default localeConfigs;

