// React
import React from 'react';

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../store/actions/actions';

// Class
import Header from '../../components/header/header';
import UserForm from './form/userForm';

// Service
import ProfileService from '../../services/profileService';
import GuestService from '../../services/guestService';
import IdentityService from '../../services/identityService';
import { FormattedMessage } from "react-intl";
import ca_privacy_icon from '../../utils/images/ca_privacy_icon.svg';


/**
 * @class Home
 * @description User will be redirected to Home page and loads this component. 
 * all other components are loaded inside this component.
 */
class Home extends React.Component {

     constructor(props) {
          super(props);
          this.state = {
               year: new Date().getFullYear()
          };
     }

     UNSAFE_componentWillMount() {
          ProfileService.setToken(this.props.accessToken);
          IdentityService.setToken(this.props.accessToken);
     }

     /**
      * @function componentDidMount
      * @description called when the component in first time mounted.
      *  this will be called before parent mounting and after child mounting
      */
     componentDidMount() {
          if (!this.props.countries) {
               this.loadAllPreferences();
          }
     }


     /**
      * @function render
      * @description repaints the component on each change of state and props value
      */
     render() {
          return (
               <>
                    <Header key="header" props={this.props} />
                    <UserForm />
                    <div className="footer">
                    <div className="footer__links">
                              <a className="link-style" href="https://help.trimble.com/s/" target="_blank" rel="noopener noreferrer" aria-label="Help - new tab"><FormattedMessage defaultMessage="Help" /></a>
                              |<a className="link-style" href="https://www.trimble.com/en/our-commitment/responsible-business/data-privacy-and-security/data-privacy-center/privacy-notice" target="_blank" rel="noopener noreferrer" aria-label="Privacy Notice - new tab"><FormattedMessage defaultMessage="Privacy Notice" /></a>
                              |<a className="link-style"  href="https://www.trimble.com/en/legal/terms-and-conditions/terms-of-use" target="_blank" rel="noopener noreferrer"><FormattedMessage defaultMessage="Terms of Use" /></a> 
                              |<a className="link-style" href="https://www.trimble.com/en/our-commitment/responsible-business/data-privacy-and-security/data-privacy-center/ca-residents-notice-at-Collection" target="_blank" rel="noopener noreferrer" aria-label="CA Notice at Collection - new tab"><FormattedMessage defaultMessage="CA Notice at Collection" /></a> 
                              |<span className="link-icon"><a rel="noopener noreferrer" aria-label="Your Privacy Choices (US) - new tab"
                              href="https://www.trimble.com/en/our-commitment/responsible-business/data-privacy-and-security/data-privacy-center/your-privacy-choices" target="_blank"><FormattedMessage defaultMessage="Your Privacy Choices (US)"/> </a><img className="icon-style" src={ca_privacy_icon} alt="California Consumer Privacy Act (CCPA) Opt-Out Icon" width="30" height="27"/>
                              </span>
                         </div>

                         <div className="copyright-info">&copy;<span>{this.state.year}, Trimble Inc. </span><span><FormattedMessage defaultMessage="All rights reserved."/></span></div>
                    </div>
               </>
          );
     }

     loadAllPreferences = () => {
          // Countries
          Promise.all([GuestService.request(
               {
                    url: process.env.REACT_APP_COUNTRIES,
                    method: 'GET'
               }
          ), GuestService.request(
               {
                    url: process.env.REACT_APP_EMBARGOED_COUNTRIES,
                    method: 'GET'
               }
          )]).then(
               ([countries, embargoed_countries]) => {
                    this.props.dispatch(
                         {
                              type: actions.storeBasics,
                              payload: { id: "embargoed_countries", value: embargoed_countries.data }
                         }
                    );

                    this.props.dispatch(
                         {
                              type: actions.storeBasics,
                              payload: { id: "countries", value: countries.data }
                         }
                    );
               }
          ).catch(
               (error) => {
                    this.setState({ isApiLoaded: true });
               }
          );
          // Languages
          GuestService.request(
               {
                    url: process.env.REACT_APP_LANGUAGES,
                    method: 'GET'
               }
          ).then(
               (response) => {
                    this.props.dispatch(
                         {
                              type: actions.storeBasics,
                              payload: { id: "languages", value: response.data }
                         }
                    );
               }
          ).catch(
               (error) => {
                    this.setState({ isApiLoaded: true });
               }
          );
          // Time Zone
          GuestService.request(
               {
                    url: process.env.REACT_APP_TIMEZONE,
                    method: 'GET'
               }
          ).then(
               (response) => {
                    this.props.dispatch(
                         {
                              type: actions.storeBasics,
                              payload: { id: "timeZones", value: response.data }
                         }
                    );
               }
          ).catch(
               (error) => {
                    this.setState({ isApiLoaded: true });
               }
          );
     }
}

/**
 * For mapping necessary state variables to current component properties
 * @param {Object} state - Immutable State Object
 * @return properties needed from state 
 */
const mapStateToProps = (state) => {
     return {
          countries: state.common.countries,
          accessToken: state.auth.access_token
     }
};

export default withRouter(connect(mapStateToProps)(Home));