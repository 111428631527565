/**
 * Usage:
 * This Service is used to access any http Call needed for Guests without using access token
 */

import axios from 'axios';

const GuestService = axios.create();

export default GuestService;

