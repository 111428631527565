import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme(
  {
    typography: {
      "fontFamily": `"Open Sans", sans-serif`,
      "fontSize": 12,
      "fontWeightLight": 300,
      "fontWeightRegular": 400,
      "fontWeightMedium": 500
    },
    palette: {
      primary: {
        main: '#0c77be',
      },
      secondary: {
        main: '#000',
      },
    },
  }
);