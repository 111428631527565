// React
import React from "react";

/// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Material
import Switch from '@material-ui/core/Switch';

import { Dialog, DialogContent,Divider, Slide, useTheme,Popover} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import Typography from '@material-ui/core/Typography';
import { useEffect } from "react";
import { useState, useCallback } from "react";
import FieldSkeleton from "../../../components/skeleton/FieldSkeleton";
import * as actions from '../../../store/actions/actions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Alert from '@material-ui/lab/Alert';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import TextsmsOutlinedIcon from '@material-ui/icons/TextsmsOutlined';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import passkeyImg from "../../../utils/images/passkey.svg";
import {flags, configs} from "../../../feature_flags";

const Transition = React.forwardRef(function Transition(props, ref) {
     return <Slide direction="left" ref={ref} {...props} />;
});

const PhoneMFAInfo = (props) => {
     return (
          <>
               {props.phoneNumber}
               <div className="notes">
                    <FormattedMessage defaultMessage="To change your preferred MFA method to use an Authenticator Application, you must first disable MFA. Then enable using your Multi-Factor Authenticator Application." />

               </div>
               <div className="notes">
                    <FormattedMessage defaultMessage="This information will be kept private and used only as a second layer of security for your account." />
               </div>
          </>
     )
}

const TOTPMFAInfo = (props) => {
     var totp_reset_end_date = configs.totp_reset_end_date;
     return (
          <>
               <div className="email-holder">
                    <Typography variant="subtitle2">
                         <FormattedMessage defaultMessage="Multi-Factor Authentication Application" />
                    </Typography>
               </div>
               {flags.show_reset_totp &&
               <div className="prompt-info">
                    <InfoOutlined  />
                    <div>
                         <div className="prompt-text">
                              <FormattedMessage defaultMessage="To keep your account safe and updated with the latest features, you will be required to reset your authenticator app connection by {date}." values={{
                              date: totp_reset_end_date
                         }}/>
                         <div className="totp-link" onClick={() => {props.openFrame(props.endpoints.enableTotpMfa)}}>
                             <FormattedMessage defaultMessage="Reset your authenticator app"/>
                         </div>
                         </div>
                    </div>
               </div>}
               <div className="notes">
                    <FormattedMessage defaultMessage="To change your preferred MFA method, you must first disable MFA." />
               </div>
          </>
     )
}

const MFAAction = (props) => {
     const [open, setOpen] = useState(false);
     const [anchorEl, setAnchorEl] = useState(null);

     const handleClick = (event) => {
       setAnchorEl(event.currentTarget);
       setOpen(true);
     };
   
     const handleClose = () => {
       setAnchorEl(null);
       setOpen(false);
     };
     const openDialog = (props,action) => {
          var mfa_type = props.mfa_type;
          handleClose();
          if(action === "disable")
               props.openFrame(mfa_type === "SOFTWARE_TOKEN_MFA" ? 
               props.endpoints.disableTotpMfa : mfa_type === "SMS_MFA" ? 
               props.endpoints.disableSmsMfa : mfa_type === "EMAIL_MFA" ? props.endpoints.disableEmailMfa : props.endpoints.disablePasskeyMfa , "disable");
          else if(action === "enable"){
               if(mfa_type === "PASSKEY_MFA_CREATE"){
                    const redirect_url = props.getFrameUrl(props.endpoints.createPasskey,mfa_type);
                    window.location.replace(redirect_url);
               }else
                    props.openFrame(mfa_type === "SOFTWARE_TOKEN_MFA" ? 
                    props.endpoints.enableTotpMfa : mfa_type === "SMS_MFA" ? 
                    props.endpoints.enableSmsMfa :  mfa_type === "EMAIL_MFA" ? props.endpoints.enableEmailMfa : props.endpoints.enablePasskeyMfa); 
          }else {
               var post_data = {
                    "mfa_type" : mfa_type
               }
               props.saveIdentityDetails(post_data);
          }
     }
   
     return (
          <>
                  {props.mfa_config[props.mfa_type] ?
                  <>
                    <MoreVertOutlinedIcon className="mfa-menu-icon"
                    onClick={handleClick} 
                     />
                  
                    { /*custom popup.opens and close on clicking menu icon
                   { open &&
                   <div className="mfa-action-list" >
                    {props.mfa_config[props.mfa_type] && !props.mfa_config[props.mfa_type].is_default &&
                         <div className="mfa-action-list-item">Make Default</div>
                    }
                         <div className="mfa-action-list-item">Remove</div>
                   </div>
                    }
                    */}

                    {/*MUI popover*/}
                    <Popover
                    className="mfa-action-pop"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    >
                         {props.mfa_config[props.mfa_type] && !props.mfa_config[props.mfa_type].is_default &&
                         <div className="mfa-action-pop-item"  
                         onClick={() => openDialog(props,"default")}>
                         <FormattedMessage defaultMessage="Make Default"/>
                         </div>
                         }
                         <div className="mfa-action-pop-item"  
                         onClick={() => openDialog(props,"disable")}>
                         <FormattedMessage defaultMessage="Remove"/>
                         </div>
                    </Popover>    
                    </>
                    :
                    <div className="list-view-action" 
                    onClick={() => openDialog(props,"enable")}>
                    <FormattedMessage defaultMessage="Set up"/>
                    </div>
                    }       
               
          </>
     )
}
const MFAConfig = (props) => {
     var mfa_config = props.mfaConfig && props.mfaConfig.configured_mfa_settings ? props.mfaConfig.configured_mfa_settings : {};
     var passkey_config = props.passkeyConfig;
     return (
          <>
               <div className="list-view-switch">
                    <h4><FormattedMessage defaultMessage="Multi-Factor Authentication" />  </h4>
                    {mfa_config && Object.keys(mfa_config).length > 0 ?
                    <h4 ><FormattedMessage defaultMessage="Enabled"/> <Switch color="primary" checked={true}  onClick={() => {props.openFrame(props.endpoints.disableAllMfa) }} /></h4>
                    :
                    <h4 ><FormattedMessage defaultMessage="Not Enabled"/></h4>
                    }
               </div>
                    
               <div><FormattedMessage defaultMessage="Keep your Trimble ID secure by adding an extra layer of protection. When you have MFA enabled, you’ll be asked to input a security code from any of the methods below to sign in."/></div>
              
               <div className="list-view-section">
               {props.hasIdentity ?
               <>
             {/*===========PASSKEY============*/}
               <div className="list-view">
                   {mfa_config["PASSKEY_MFA"] ?
                    <>
                         <div className="each-list-view">
                         <CheckCircleOutlinedIcon className="list-view-icon-enabled"/>
                    <div>
                         <div className="list-view-title"> <FormattedMessage defaultMessage="Passkey"/>
                         {mfa_config["PASSKEY_MFA"] && mfa_config["PASSKEY_MFA"].is_default && <span className="custom-badge"><FormattedMessage defaultMessage="Default"/></span>}
                         </div>
                         <div><FormattedMessage defaultMessage="Fingerprint, facial recognition, PIN, and more."/></div>
                    </div>
                    </div>
                     <MFAAction 
                     mfa_config={mfa_config}
                     mfa_type = "PASSKEY_MFA"
                     endpoints= {props.endpoints}
                     openFrame={props.openFrame}
                     saveIdentityDetails={props.saveIdentityDetails}
                     getFrameUrl = {props.getFrameUrl}
                    />
                    </>
                     :
                    /*Hiding passkey enable option if flag is OFF*/
                    (flags.show_passkey_as_mfa &&
                    <>
                     <div className="each-list-view">
                    <img src={passkeyImg} className="passkey-icon-disabled" alt="passkey" width="21" height="21"/>
                         
                    <div>
                         {/*Check if passkey is enabled as first factor*/}
                         {(passkey_config && passkey_config.length > 0) ? 
                              <div className="list-view-title"> <FormattedMessage defaultMessage="Use your existing passkey"/>
                              </div>
                              :
                              <div className="list-view-title"> <FormattedMessage defaultMessage="Passkey"/>
                              </div>
                         }
                         <div><FormattedMessage defaultMessage="Fingerprint, facial recognition, PIN, and more."/></div>
                    
                    </div>
                    </div>
                    <MFAAction 
                    mfa_config={mfa_config}
                    mfa_type = {passkey_config && passkey_config.length > 0 ? "PASSKEY_MFA" : "PASSKEY_MFA_CREATE"}
                    endpoints= {props.endpoints}
                    openFrame={props.openFrame}
                    saveIdentityDetails={props.saveIdentityDetails}
                    getFrameUrl = {props.getFrameUrl}
             
                    />
                    </>)
                    }
              </div>
              
               {/*=============TOTP===========*/}
               <div className="list-view">
                    <div className="each-list-view">
                         {mfa_config["SOFTWARE_TOKEN_MFA"] ? 
                         <CheckCircleOutlinedIcon className="list-view-icon-enabled"/>
                         :<SmartphoneIcon className="list-view-icon"/>
                         }  
                         <div>
                              <div className="list-view-title"> <FormattedMessage defaultMessage="Authenticator App"/> 
                              {mfa_config["SOFTWARE_TOKEN_MFA"] && mfa_config["SOFTWARE_TOKEN_MFA"].is_default && <span className="custom-badge"><FormattedMessage defaultMessage="Default"/></span>}
                              </div>
                              {mfa_config["SOFTWARE_TOKEN_MFA"] ?
                              <div><FormattedMessage defaultMessage="Your authenticator app has been connected."/></div>
                              :<div><FormattedMessage defaultMessage="Get a code from an authenticator app like Google Authenticator."/></div>
                              }
                         </div>
                    </div>
               
                    <MFAAction 
                    mfa_config={mfa_config}
                    mfa_type = "SOFTWARE_TOKEN_MFA"
                    endpoints= {props.endpoints}
                    openFrame={props.openFrame}
                    saveIdentityDetails={props.saveIdentityDetails}
                    getFrameUrl = {props.getFrameUrl}
               
                    />                     
                  
              </div>
               {/*================EMAIl============*/}
              <div className="list-view">
                    <div className="each-list-view">
                         {mfa_config["EMAIL_MFA"] ? 
                         <CheckCircleOutlinedIcon className="list-view-icon-enabled"/>
                         :<MailOutlineIcon className="list-view-icon"/>
                         }
                         <div>
                              <div className="list-view-title"> <FormattedMessage defaultMessage="Secondary Email"/>
                              {mfa_config["EMAIL_MFA"] && mfa_config["EMAIL_MFA"].is_default && <span className="custom-badge"><FormattedMessage defaultMessage="Default"/></span>}
                              </div>
                              {mfa_config["EMAIL_MFA"] && mfa_config["EMAIL_MFA"].destination ?
                              <div>{mfa_config["EMAIL_MFA"].destination}</div>
                              :<div><FormattedMessage defaultMessage="Get a code sent to another email."/></div>
                               }
                         </div>
                    </div>
                    <MFAAction 
                    mfa_config={mfa_config}
                    mfa_type = "EMAIL_MFA"
                    endpoints= {props.endpoints}
                    openFrame={props.openFrame}
                    saveIdentityDetails={props.saveIdentityDetails}
                    getFrameUrl = {props.getFrameUrl}
             
                    />
                     
              </div>
               {/*==========Showing SMS MFA option only for existing users who has SMS MFA enabled===============*/ 
               <>
              <div className="list-view">
               {mfa_config["SMS_MFA"]?
               <>
                    <div className="each-list-view">
                         {mfa_config["SMS_MFA"] ? 
                         <CheckCircleOutlinedIcon className="list-view-icon-enabled"/>
                         :<TextsmsOutlinedIcon className="list-view-icon"/>
                         }
                         <div>
                              <div className="list-view-title"> <FormattedMessage defaultMessage="SMS Phone Number"/>
                              {mfa_config["SMS_MFA"] && mfa_config["SMS_MFA"].is_default && <span className="custom-badge"><FormattedMessage defaultMessage="Default"/></span>}
                              </div>
                              {mfa_config["SMS_MFA"] && mfa_config["SMS_MFA"].destination ?
                              <div>{mfa_config["SMS_MFA"].destination.substr(1)}</div>
                              :
                              <div><FormattedMessage defaultMessage="Get a code texted to your phone."/></div>
                              }
                         </div>
                    </div>
                    <MFAAction 
                    mfa_config={mfa_config}
                    mfa_type = "SMS_MFA"
                    endpoints= {props.endpoints}
                    openFrame={props.openFrame}
                    saveIdentityDetails={props.saveIdentityDetails}
                    getFrameUrl = {props.getFrameUrl}
          
                    />
               </>
               :
               (flags.show_sms_mfa &&
               <>
                    <div className="each-list-view">
                         <TextsmsOutlinedIcon className="list-view-icon"/>
                         <div>
                              <div className="list-view-title"> <FormattedMessage defaultMessage="SMS Phone Number"/></div>
                              <div><FormattedMessage defaultMessage="Get a code texted to your phone."/></div>
                         </div>
                    </div>
                    <MFAAction 
                    mfa_config={mfa_config}
                    mfa_type = "SMS_MFA"
                    endpoints= {props.endpoints}
                    openFrame={props.openFrame}
                    saveIdentityDetails={props.saveIdentityDetails}
          
                    />
               </>)}
              </div>

           
              {mfa_config["SMS_MFA"] && !flags.show_sms_mfa &&
                    <div className="prompt-info">
                         <InfoOutlined  />
                         <div>
                              <div className="prompt-text">
                                   <FormattedMessage defaultMessage="SMS verification will no longer be available as a multi-factor authentication (MFA) method after {date}. To keep your account secure, we recommend configuring another default MFA method by {date}." values={{
                                   date: configs.sms_mfa_end_date
                              }}/>

                              </div>
                         </div>
                    </div>}
               </>
                   
          }
              </>
              :<FieldSkeleton/>
          }

             </div>
             

          </>
     )
}
const MFAInfo = (props) => {
     return (<>
          <div className="notes">
               <FormattedMessage defaultMessage="When enabled, you will be asked to sign in with your password and a unique verification code based on the preferred MFA Authentication method associated with your account." />
          </div>

          {
               props.isMfaExists && (<div className="mfa-methods">
                    <h4><FormattedMessage defaultMessage="Preferred Method" /></h4>
                    {
                         props.mfaMethodType === "SOFTWARE_TOKEN_MFA" && <TOTPMFAInfo {...props} />
                    }

                    {
                         props.mfaMethodType === "SMS_MFA" && <PhoneMFAInfo {...props} />
                    }
               </div>)
               }
            
              
     </>);
}
const PasskeyConfig = (props) => {
     var passkey_config = props.passkeyConfig;
     const getLocaleTime = (time) => {
          var myDate = new Date(time*1000);
          return myDate.toLocaleDateString('en-us', {year:"numeric", month:"short", day:"numeric"});
     }
     var create_passkey_endpoint = props.isManagedUser ? props.endpoints.muCreatePasskey : props.endpoints.createPasskey;
     var manage_passkey_endpoint = props.isManagedUser ? props.endpoints.muManagePasskey : props.endpoints.managePasskey;

     const redirect_url = props.getFrameUrl(create_passkey_endpoint);
     return(
          <>
           <div className="list-view-switch">
                    <h4><FormattedMessage defaultMessage="Manage passkeys"/>  </h4>
                    {passkey_config && passkey_config.length === 3 ?
                    <div><FormattedMessage defaultMessage="Max of 3 passkeys"/> </div>
                    :
                    <>
                    <a href={redirect_url} className="link-button" target="_self"><FormattedMessage defaultMessage="Create a passkey"/></a>
                    </>
                    }
               </div>
               {props.hasIdentity ?
               <>
               {passkey_config && passkey_config.length > 0?
               <div className="list-view-section">
                   
                         {passkey_config.map((passkey,i) => 
                          <div key={i} className="list-view">
                                   <div className="each-list-view">
                                         <img src={passkeyImg} className="passkey-icon-static" alt="passkey" width="21" height="21"/>
                                        <div>
                                             <div className="list-view-title">{passkey.passkey_name}</div>
                                             <div className="passkey-time"><span><FormattedMessage defaultMessage="Created:"/></span> {getLocaleTime(passkey.created_time)}</div>
                                             <div className="passkey-time"><span><FormattedMessage defaultMessage="Last used:"/></span> {getLocaleTime(passkey.last_used_time)}</div>
                                        </div>
                                   </div>
                                   <div className="passkey-action">
                                        <Tooltip title={<div style={{ fontSize:"12px",padding:"2px"}}>Edit name</div>} arrow placement="top">
                                             <Edit className="passkey-icon" onClick={() => {props.openFrame(manage_passkey_endpoint,"edit_passkey", window.btoa(JSON.stringify(passkey)) )}}/>
                                        </Tooltip>
                                        <Tooltip title={<div style={{ fontSize:"12px",padding:"2px"}}>Remove</div>} arrow placement="top">
                                             <Close className="passkey-icon" onClick={() => {props.openFrame(manage_passkey_endpoint,"delete_passkey", window.btoa(JSON.stringify(passkey)) )}}/>
                                        </Tooltip>
                                   </div>
                         </div>
                         )}                                   
               </div>
               :    
               <div><FormattedMessage defaultMessage="Sign in with a passkey instead of your password. Passkeys are easier and more secure than passwords by using biometrics, device screen locks and other options that help identify you."/> 
               </div>
               }
               </>
          :
          <>
               <FieldSkeleton/>
         
               <FieldSkeleton />
          </>
          }
          </>
     )
}
const RecoveryInfo = (props) => {
     return (<>
          <div className="notes">
               <FormattedMessage defaultMessage="We recommend having a backup email in case you need to recover your account" />
          </div>
          {
               props.recoveryEmail && (
                    <Typography variant="subtitle1">
                         {props.recoveryEmail}
                    </Typography>
               )
          }
          <div className="link-holder mtpx-1">
               {
                    props.hasIdentity ? (
                         <>
                              {
                                   props.recoveryEmail ? (
                                        <div className="link" onClick={props.onChangeLinkClick} >
                                             <FormattedMessage defaultMessage="Manage recovery email" />
                                        </div>) : (
                                        <div className="link" onClick={props.onAddLinkClick} >
                                             <FormattedMessage defaultMessage="Add recovery email" />
                                        </div>
                                   )
                              }
                         </>
                    ) : (
                         <FieldSkeleton />
                    )
               }
          </div>
     </>);
}

const AccountDialog = (props) => {
     const [loaded, setLoaded] = useState(false);
     const theme = useTheme();
     const loadHandler = useCallback(() => setLoaded(true), []);
     const closeHandler = useCallback(() => props.onClose(null), [props]);
     const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

     useEffect(() => {
          let listener = null;

          if (loaded && !listener) {
               const childWindow = document.getElementById('identity-frame').contentWindow;

               listener = message => {
                    if (message.source !== childWindow) {
                         return; // Skip message in this event listener
                    }

                    if (message.data.status === "complete") {
                         if (props.onClose) {
                              props.onClose(message.data)
                         }
                    }
               };

               window.addEventListener('message', listener, false);
          }

          return () => {
               if (listener) {
                    window.removeEventListener('message', listener, false);
               }
          };
     }, [props, loaded]);

     return (
          <Dialog
               open={props.open}
               TransitionComponent={Transition}
               keepMounted
               onClose={closeHandler}
               aria-labelledby="alert-dialog-slide-title"
               aria-describedby="alert-dialog-slide-description"
               fullWidth={true}
               className="accountFrameDialog"
               fullScreen={fullScreen}
               disableBackdropClick={true}
               disableEscapeKeyDown={true}
          >
               <DialogContent>
                    {/* <span className="popup-cancel-button" onClick={this.closeFrame}>Cancel</span> */}
                    <iframe id="identity-frame" onLoad={loadHandler} src={props.frameUrl} title="Account Link" allow= "publickey-credentials-get *"/>
               </DialogContent>
          </Dialog>
     )
}



/**
 * @class Basics
 * @description component contains Account Details
 */
class Accounts extends React.PureComponent {

     /**
      * @function constructor
      * @param {*} props - Having properties passed by parent element
      *  * used for state initialization
      */
     constructor(props) {
          super(props);

          this.state = {
               frameUrl: null
          };

          this.bindedOpenFrame = this.openFrame.bind(this)
     }

     componentDidUpdate(_prevProps, prevState) {
          //     /
     }

     getFrameUrl = (frameUrl,action,data) => {
          let params = {
               username: this.props.isManagedUser ? this.props.preferredUsername : this.props.emails,
               application: this.props.applicationId,
               ui_locales: this.props.locale,
               federation_origin: this.props.federation_origin,
               origin: window.location.origin,
               action: action,
               data: data
          }
          /*For disabling MFA,add a param in iFrame URL if only one MFA method is present to differentiate consent in iFrame*/
          if(action === "disable" && this.props.mfaConfig && this.props.mfaConfig.configured_mfa_settings 
          && Object.keys(this.props.mfaConfig.configured_mfa_settings).length === 1){
               params.one_mfa = "true";
          }
          const queryComponents = Object.keys(params).reduce((agg, val) => {
               if (typeof params[val] === "string" && params[val]?.length > 0) {
                    agg.push(`${val}=${encodeURIComponent(params[val])}`)
               }

               return agg
          }, []);
          return queryComponents.length > 0 ? `${frameUrl}?${queryComponents.join("&")}` : frameUrl;
     }

     openFrame = (frameUrl,action,data) => {
         const encodedUrl = this.getFrameUrl(frameUrl,action,data);

          this.setState({
               frameUrl: encodedUrl
          });
     }

     closeFrame = (data) => {
          if (this.props.onChanges) {
               if (data?.multifactor_changed || data?.change_email || data?.change_recovery_email || data?.remove_recovery_email || data?.passkey_changed) {
                    this.props.onChanges();
               } else if (data?.password_changed || data?.account_deleted || data?.change_data_region || data?.policy_changed) {
                    this.props.dispatch({ type: actions.signOut });
               }
          }

          this.setState({ frameUrl: null });
     }

     /**
      * @function render
      * @description repaints the component on each change of state and props value
      */
     render() {
          let primaryEmail, phoneNumber;
          if (this.props.emails) {
               primaryEmail = (
                    <div className="email-holder">
                         <Typography variant="subtitle2" component="label">
                              <FormattedMessage defaultMessage="Email Address" />
                         </Typography>
                         <Typography variant="subtitle1">
                              {this.props.emails}
                         </Typography>
                    </div>
               );
          } else {
               primaryEmail = <FieldSkeleton />;
          }
          if (!this.props.phones) {
               phoneNumber = <FieldSkeleton />;

          } else {
               phoneNumber =
                    (
                         <div className="email-holder">
                              <Typography variant="subtitle2" component="label">
                                   <FormattedMessage defaultMessage="SMS Phone Number" />
                              </Typography>
                              <Typography variant="subtitle1">
                                   {
                                        this.props.phones ?? <FormattedMessage defaultMessage="Not Available" />
                                   }
                              </Typography>
                         </div>
                    )
          }
          var show_passkey = (this.props.isTIDUser || this.props.isSocialUser) ? flags.show_passkey : this.props.isManagedUser ? flags.show_mu_passkey : false;
          return (
               <div className="card accounts">
                    <h3>
                         <FormattedMessage defaultMessage="Account Management" />
                    </h3>
                    <div className="form-holder">
                    {this.props.hasIdentity ? 
                         <>
                         <div className="identification-holder">
                              
                              {
                              /* Managed users */
                              this.props.isManagedUser ? 
                              <Alert variant="outlined" severity="info">
                                   <FormattedMessage defaultMessage="If you need to change your password, or make changes to your account, please contact your administrator." />
                              </Alert>
                              :
                              /* Federated users */
                              (!(this.props.isTIDUser || this.props.isSocialUser)) ? 
                              <Alert variant="outlined" severity="info">
                                   <FormattedMessage defaultMessage="If you need to change your email address, password, or make changes to your account, please contact your administrator." />
                              </Alert>
                              :
                              /* Native and Social users */
                              <>
                              {(this.props.isTIDUser || this.props.isSocialUser) && <>
                                   <div className="email-section">
                                        {primaryEmail}
                                   
                                        {(this.props.isTIDUser && <div className="link-holder">
                                             <div className="link" onClick={() => { this.openFrame(this.props.endpoints.changeEmail) }}><FormattedMessage defaultMessage="Change email address" /></div>
                                        </div>)}
                                   </div>
                              </>}

                              {this.props.isTIDUser && <>
                                   <div className="link-holder">
                                        <div className="link" onClick={() => { this.openFrame(this.props.endpoints.changePassword) }}><FormattedMessage defaultMessage="Change password" /></div>
                                        <div className="link" onClick={() => { this.openFrame(this.props.endpoints.deleteAccount) }}><FormattedMessage defaultMessage="Delete your Trimble Identity" /></div>
                                   </div>
                              </>}

                              {this.props.isSocialUser && <>
                                   <div className="link-holder">
                                        <div className="link" onClick={() => { this.openFrame(this.props.endpoints.deleteSocialAccount) }}><FormattedMessage defaultMessage="Delete your Trimble Identity" /></div>
                                   </div>
                              </>}
                              </>}
                             
                         </div>
                 
                         {show_passkey &&
                         <div className="passkey-holder">
                              <PasskeyConfig
                                   passkeyConfig= {this.props.passkeyConfig}
                                   hasIdentity={this.props.hasIdentity}
                                   endpoints={this.props.endpoints}
                                   getFrameUrl = {this.getFrameUrl}
                                   openFrame={this.openFrame}
                                   isManagedUser = {this.props.isManagedUser}
                                   />
                         </div>}
                
                         {(this.props.isTIDUser || (this.props.isSocialUser && this.props.mfaConfig && this.props.mfaConfig.configured_mfa_settings 
                              && Object.keys(this.props.mfaConfig.configured_mfa_settings).length > 0)) && <div className="mfa-holder">
                         {this.props.isMfaExists ?
                         <>
                              <h4><FormattedMessage defaultMessage="Multi-Factor Authentication" /><Switch color="primary" onClick={this.showMFAFrame} checked={this.props.isMfaExists} /></h4>
                              <MFAInfo mfaMethodType={this.props.mfaMethodType} 
                              isMfaExists={this.props.isMfaExists} 
                              phoneNumber={phoneNumber}
                              openFrame={this.openFrame}
                              endpoints={this.props.endpoints}
                             
                              />
                         </>
                              : 
                         <>
                             <MFAConfig
                              mfaConfig={this.props.mfaConfig}
                              passkeyConfig={this.props.passkeyConfig}
                              openFrame={this.openFrame}
                              getFrameUrl = {this.getFrameUrl}
                              endpoints={this.props.endpoints}
                              hasIdentity={this.props.hasIdentity}
                              saveIdentityDetails={this.props.saveIdentityDetails}
                              isSocialUser={this.props.isSocialUser}
                              />
                         </>
                         }
                         
                         </div>}
                         {/*this.props.isTIDUser && <div className="recovery-holder">
                              <Divider />
                              <h4><FormattedMessage defaultMessage="Recovery Email" /></h4>
                              <RecoveryInfo onChangeLinkClick={() => { this.openFrame(this.props.endpoints.changeRecoveryEmail) }} onAddLinkClick={() => { this.openFrame(this.props.endpoints.addRecoveryEmail) }} recoveryEmail={this.props.recoveryEmail} hasIdentity={this.props.hasIdentity}/>
                    </div>*/}
                         {/* <div className="link-holder">
                              <div className="link" onClick={() => { this.openFrame(process.env.REACT_APP_CHANGE_PASSWORD_LINK) }}>Change SMS phone number</div>

                         </div> */}
                         </>: 
                         <FieldSkeleton/>
                    }
                         <AccountDialog frameUrl={this.state.frameUrl} open={(this.state.frameUrl !== null) ? true : false} onClose={this.closeFrame} />
                    </div>
               </div >
          );
     }
     showMFAFrame = () => {
          this.openFrame(this.props.endpoints.disableMFA);
     }
}

/**
 * For mapping necessary state variables to current component properties
 * @param {Object} state - Immutable State Object
 * @return properties needed from state 
 */
const mapStateToProps = (state) => {
     const regionEndpoints = {
          'us': process.env.REACT_APP_US_IDENTITY_ENDPOINT,
          'eu': process.env.REACT_APP_EU_IDENTITY_ENDPOINT,
          'uk': process.env.REACT_APP_UK_IDENTITY_ENDPOINT,
          'eu-gb': process.env.REACT_APP_UK_IDENTITY_ENDPOINT,
          'ap-au': process.env.REACT_APP_AU_IDENTITY_ENDPOINT
     };

     const userRegion = regionEndpoints[state.auth?.profile?.data_region ?? "us"]
     const federation_origin = (state.auth?.profile?.federation_origin) ?? null;
     const is_managed_user = (state.userManager?.identity?.preferred_username) ? true : false;
     const is_social_user = federation_origin === "google" || federation_origin === "apple" || federation_origin === "azure_social";
     return {
          emails: (state.userManager?.identity?.email) ?? null,
          phones: (state.userManager?.identity?.phone_number) ?? null,
          isMfaExists: (state.userManager?.identity?.mfa_settings?.length > 0) ? true : false,
          isTIDUser: ((state.auth?.profile?.amr ?? []).some(value => (value === "password" || value === "passkey"))) && !is_managed_user && !is_social_user,
          isManagedUser: is_managed_user,
          preferredUsername: (state.userManager?.identity?.preferred_username) ?? null,
          mfaConfig:  state.userManager?.identity?.mfa_config,
          passkeyConfig: state.userManager?.identity?.passkey_config,
          isSocialUser: is_social_user,
          mfaMethodType: state.userManager?.identity?.mfa_settings?.[0],
          applicationId: (state.auth?.profile?.azp) ?? null,
          endpoints: {
               changeEmail: `${userRegion}/ui/change_email.html`,
               changePassword: `${userRegion}/ui/change_password.html`,
               changeDataRegion: `${userRegion}/ui/change_data_region.html`,
               deleteAccount: `${userRegion}/ui/delete_account.html`,
               enableMFA: `${userRegion}/ui/enable_multifactor.html`,
               disableMFA: `${userRegion}/ui/disable_multifactor.html`,
               changeRecoveryEmail: `${userRegion}/ui/change_recovery_email.html`,
               addRecoveryEmail: `${userRegion}/ui/add_recovery_email.html`,
               deleteSocialAccount: `${userRegion}/ui/delete_social_account.html`,
               enableTotpMfa: `${userRegion}/ui/enable_mfa_totp.html`,
               enableEmailMfa: `${userRegion}/ui/enable_mfa_email.html`,
               enableSmsMfa: `${userRegion}/ui/enable_mfa_sms.html`,
               disableAllMfa: `${userRegion}/ui/disable_mfa_all.html`,
               disableTotpMfa: `${userRegion}/ui/disable_mfa_totp.html`,
               disableSmsMfa: `${userRegion}/ui/disable_mfa_sms.html`,
               disableEmailMfa: `${userRegion}/ui/disable_mfa_email.html`,
               managePasskey: `${userRegion}/ui/manage_passkey.html`,
               createPasskey: `${userRegion}/ui/create_passkey.html`,
               enablePasskeyMfa: `${userRegion}/ui/enable_mfa_passkey.html`,
               disablePasskeyMfa: `${userRegion}/ui/disable_mfa_passkey.html`,
               muCreatePasskey: `${userRegion}/ui/mu_create_passkey.html`,
               muManagePasskey: `${userRegion}/ui/mu_manage_passkey.html`
          },
          region: state.auth?.profile?.data_region,
          locale: (state.userManager?.language) ?? null,
          recoveryEmail: (state.userManager?.identity?.recovery_email) ?? null,
          hasIdentity: (state.userManager?.identity) ?? null,
          hasRegion: false,
          federation_origin
     }
};
export default withRouter(connect(mapStateToProps)(Accounts));