import * as oidcActions from "redux-oidc";
import * as actions from "../actions/actions";
import { updateObject } from "../../utils";
import authenticationManager from "../../utils/authenticationManager";

import { isValid } from "../../utils";

const INITIAL_STATE = {

};

/**
 * @class Authenticator
 * @description Mapping set of Reducers for each Authentication Actions
 * @param {Object} action - Action from action creator
 * @param {Object} state - Default application state
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action = {}) => {
     switch (action.type) {
          case oidcActions.USER_FOUND: // a valid user is found (on startup, after token refresh or token callback) - updates the user object with the new user object.
          case oidcActions.LOAD_USER_ERROR: // the user manager's loading process produces an error. - no effect on reducer
          case oidcActions.USER_EXPIRING: // 5 minutes before the access token expires (oidc-client default) -  no effect on reducer
          case oidcActions.USER_EXPIRED: // no valid user is found on startup or a valid user object expires - sets the user object to null and isLoadingUser to false.
          case oidcActions.SILENT_RENEW_ERROR: // the silent renewal process fails - sets the user object to null and isLoadingUser to false.
          case oidcActions.LOADING_USER:  // the user object has expired and a new user is about to be loaded (middlware only!) - sets the isLoadingUser flag to true.
          case oidcActions.USER_LOADED:
               return saveContents(state, action);
          case oidcActions.SESSION_TERMINATED: // the user logs out (with a call to the userManager function)  - sets the user object to null and isLoadingUser to false
          case oidcActions.USER_SIGNED_OUT: //the user is logged out at the auth server - sets the user object to null and isLoadingUser to false - 
               return signoutReducer(state, action);
          case actions.loadUserData: // Set initial User data from Trimble ID to state
               return setInitialUserDetails(state, action);
          case actions.signOut:
               return signOut(state, action);
          default: return setInitialUserDetails(state, action);
     }
};

/**
 * To save User data in successful redirection
 * @param {Object} state - auth state
 * @param {Object} action 
 * @return {Object} New state
 */
const saveContents = (state, action) => {
     return updateObject(
          state, action.payload
     );
}

/**
 * @function signoutReducer
 * @description To clear session before signout
 * @param {Object} state - auth state
 * @param {Object} action 
 * @return {Object} New state
 */
const signoutReducer = (state, action) => {
     return updateObject(
          state, {}
     );
}


/**
 * @function setInitialUserDetails
 * @description initializing user accesstoken
 * @param {Object} state - auth state
 * @param {Object} action 
 * @return {Object} New state
 */
const setInitialUserDetails = (state, action) => {

     let auth = typeof (action.payload) === 'string' ? checkHashString(action.payload) : null;
     // console.log(state, 'setInitialUserDetails', action)
     if (!isValid(auth)) {
          auth = checkLocalStorage();
     }
     return updateObject(
          state, auth
     );
}

/**
 * @function signOut
 * @description to log off from the application
 * @param {*} state 
 * @param {*} action 
 */

const signOut = (state) => {
     var preferred_username = state.profile && state.profile.preferred_username;
     var account = preferred_username && preferred_username.split('.')[0];
     var username = preferred_username && preferred_username.split('.')[1];
     var state_param = window.btoa(`auth_workflow=managed_user&account=${account}&login_hint=${username}`);
    
     var parameters = {
          id_token_hint: state.id_token,
          extraQueryParams:{}
     };
     /*Add extra query params only for managed user */
     if(preferred_username)
          parameters.extraQueryParams.state = state_param;
         
     authenticationManager.signoutRedirect(parameters);
     
     return updateObject(
          state, {}
     );
}

/**
 * @function checkHashString
 * @description to check accesstoken exist in url
 * @param {*} url 
 */
const checkHashString = (url) => {

     let auth = {};
     if (url) {
          let hashString = url.substring(1);
          const hashParams = hashString.split('&');

          hashParams.forEach((param, index) => {
               const queryPair = param.split('=');
               // Save the store parameter if exists
               switch (queryPair[0]) {
                    case 'access_token':
                         auth['access_token'] = queryPair[1];
                         localStorage.setItem('access_token', auth['access_token']);
                         break;

                    case 'expires_in':
                         auth['expires_in'] = queryPair[1];
                         localStorage.setItem('expires_in', auth['expires_in']);
                         break;

                    default: break;
               }
          });
     }
     return (isValid(auth['access_token'])) ? auth : null;
}

/**
 * @function checkLocalStorage
 * @description to check access token exists in local storage
 */
const checkLocalStorage = () => {
     if (isValid(localStorage.getItem('access_token'))) {
          return {
               'access_token': localStorage.getItem('access_token'),
               'expires_in': localStorage.getItem('expires_in'),
          }
     }
     return null;
}