import React from "react";
import PropTypes from "prop-types";

import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import { Controller } from 'react-hook-form';
import { Validations } from "./validations";
import classNames from 'classnames';

/**
 * @component SelectWrapper
 * @param {*} props 
 */
export const SelectWrapper = (props) => {

     if (window.formHooks.errors[props.id]) {
          switch (window.formHooks.errors[props.id].type) {
               case Validations.required:
                    window.formHooks.errors[props.id].message = props.validations.required.message;
                    break;
               case Validations.validate:
                    window.formHooks.errors[props.id].message = props.validations.validate.message;
                    break;
               default:
                    break;
          }
     }

     return (
          <FormControl>
               <label className={
                    classNames({
                         "label": true,
                         "required": (props.validations?.required) ? true : false
                    })
               }>
                    {props.label}
               </label>
               <Controller
                    control={window.formHooks.control}
                    name={props.id}
                    defaultValue={props.initialValue ? props.initialValue : ""}
                    render={
                         (controller) => (
                              <Select
                                   name={controller.name}
                                   value={controller.value}
                                   disabled={props.disabled}
                                   placeholder={props.placeHolder}
                                   onChange={(event) => { controller.onChange(event.target.value); if (props.onChange) { props.onChange(event.target.value); } }}
                                   aria-invalid={(window.formHooks.errors?.[props.id]) && controller.value === "" ? true : false}
                                   aria-label={window.formHooks.formState.isDirty && props.initialValue !== controller.value ? "touched" : ""}
                              >
                                   {props.children}
                              </Select>
                         )
                    }
               />
               { window.formHooks.errors[props.id] && <div className="element-level-error">{window.formHooks.errors[props.id].message}</div>}
          </FormControl >
     );

}

SelectWrapper.propTypes = {
     label: PropTypes.any.isRequired,
     type: PropTypes.string,
     id: PropTypes.string.isRequired,
     initialValue: PropTypes.string,
     placeHolder: PropTypes.string,
     required: PropTypes.bool,
     value: PropTypes.string,
     requiredErrorMessage: PropTypes.string,
     helperMessage: PropTypes.string,
     disabled: PropTypes.bool,
     onChange: PropTypes.func,
     pattern: PropTypes.any
};

SelectWrapper.defaultProps = {
     label: null,
     type: "text",
     id: "text",
     initialValue: "",
     placeHolder: "Enter the value",
     required: false,
     value: "",
     requiredErrorMessage: "This field is required",
     helperMessage: "",
     disabled: false,
     onChange: null,
     pattern: null
};
