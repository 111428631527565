// React
import React, { useEffect, useMemo, useState } from 'react';

// Redux
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import { Log } from 'oidc-client';
import store from './store/store';
import { saveState } from './store/sessionStorage';
import * as actions from './store/actions/actions';

// Material
import { ThemeProvider } from '@material-ui/core';
import { theme } from './themes/theme';

// Service
import ProfileService from './services/profileService';
import IdentityService from './services/identityService';

// Class
import { Routes } from './routes';

// Form Validation
import { useForm } from 'react-hook-form';

import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { IntlProvider } from 'react-intl';
import localeConfigs from './locale-config';
import Loader from './components/loader';

// polyfill
Number.isInteger = Number.isInteger || function (value) {
     return typeof value === 'number' &&
          isFinite(value) &&
          Math.floor(value) === value;
};

/**
 * @component App
 * @description this function calls initally when application is loaded by index.js
 * @param {*} props 
 */
function _App(props) {
     // Enabling Logger for OIDC Redux in Development Mode
     if (process.env.NODE_ENV === "development") {
          Log.logger = console;
          Log.level = Log.INFO;
     }

     // Save User details if # entry returned in URL
     if (props.location.hash) {
          props.dispatch({ type: actions.loadUserData, payload: props.location.hash });
     }

     store.subscribe(() => {
          saveState(store.getState());
     });

     let redirectResponse = (response) => {
          return response;
     }
     let interceptError = (error) => {
          const appEnvironment = process.env.REACT_APP_ENVIRONMENT
          if (appEnvironment === "prod" || appEnvironment === "stg") {
               if (error.response.status === 401 || error.response.status === 0) {
                    props.dispatch({type: actions.signOut});
               } else {
                    return Promise.reject(error);
               }
          }
     }
     ProfileService.interceptors.response.use(redirectResponse, interceptError);
     IdentityService.interceptors.response.use(redirectResponse, interceptError);

     // Initializing form Hooks.
     // This hooks can be initialized only in functional components
     window.formHooks = useForm({
          mode: "onChange",
          reValidateMode: "onBlur"
     });
     
     const queryParams = new URLSearchParams(window.location.search);
     if(queryParams.get("signout")){
          props.dispatch({type: actions.signOut});
          return;
     }
     return (
          <ThemeProvider theme={theme}>
               <div className="layout" key="layout">
                    <Routes />
               </div>
          </ThemeProvider>

     );
}

async function loadLocaleData(config) {
     const polyfills = [];

     if (shouldPolyfillLocale()) {
          polyfills.push(import('@formatjs/intl-locale/polyfill'));
     }

     if (polyfills.length) {
          await Promise.all(polyfills);
     }

     return import(
          /* webpackChunkName: "lang-[request]" */
          `../compiled-lang/${config.source}.json`
     );
}

function mapDispatchToProps(dispatch) {
     return {
          dispatch
     };
}

const mapStateToProps = (state) => {
     return {
          languageCode: state.userManager ? state.userManager.language : "en"
     }
};

const App = withRouter(connect(null, mapDispatchToProps)(_App));

const _RootApp = ({ languageCode }) => {
     const [localeConfig, setLocaleConfig] = useState({
          locale: "en",
          loaded: false,
          messages: {

          }
     });

     const locales = useMemo(() => {
          const langs = (
               window.navigator.languages ? window.navigator.languages : [window.navigator.language]
          ).filter(value => !!value);

          if (languageCode) {
               const locale = languageCode.replace("_", "-");

               return [
                    locale,
                    ...langs
               ]
          }

          return langs;

     }, [languageCode]);

     useEffect(() => {
          const config = locales.reduce((agg, locale) => {
               const localeConfig = localeConfigs.find(value => value.locales.some(key => key === locale))

               if (localeConfig) {
                    agg.push(localeConfig)
               }

               return agg;
          }, [])[0] ?? localeConfigs[0];

          loadLocaleData(config).then(messageModule => {
               setLocaleConfig(state => {
                    if (state.locale !== config.source) {
                         return {
                              locale: config.source,
                              loaded: true,
                              messages: messageModule.default
                         }
                    } else if (!state.loaded) {
                         return {
                              ...state,
                              loaded: true
                         }
                    }

                    return state;
               })
          })

     }, [locales])

     return (
          <IntlProvider defaultLocale={'en'} key={localeConfig.locale} locale={localeConfig.locale} messages={localeConfig.messages}>
               {
                    localeConfig.loaded ? <App /> : <Loader showBackdrop />
               }
          </IntlProvider>
     )
}

export const RootApp = connect(mapStateToProps, null)(_RootApp)