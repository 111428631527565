// React
import React from 'react';

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Utils
import Loader from '../../components/loader';

// Service
import AuthService from '../../services/authService';

/**
 * @class Login
 * @description This page appears when Application redirects to Identity Page for adding Loader
 */
class Login extends React.Component {

     /**
      * componentDidMount - Lifecycle Hook
      * For redirecting to sigin page when this component is mounted
      */
     componentDidMount() {
          if (this.props.isAuthenticated) {
               this.props.dispatch(this.props.history.push("/home"));
          } else {
               AuthService.signInRedirect(this.props);
          }
     }


     /**
      * @function render
      * @description repaints the component on each change of state and props value
      */
     render() {
          return (
               <Loader showBackdrop />
          );
     }
}


/**
 * For mapping necessary state variables to current component properties
 * @param {Object} state - Immutable State Object
 * @return properties needed from state 
 */
const mapStateToProps = (state) => {

     return {
          isAuthenticated: AuthService.isAuthenticated(state)
     }
};

export default withRouter(connect(mapStateToProps)(Login));
