// React
import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";

// Redux, OIDC
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import * as serviceWorker from './serviceWorker';

// CSS and IE Polyfills
import './index.scss';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// Application
import store from './store/store';
import authenticationManager from './utils/authenticationManager';

import { RootApp } from './app';

const Init = () => {
     return (
          <Provider store={store}>
               <OidcProvider store={store} userManager={authenticationManager}>
                    <BrowserRouter>
                         <RootApp />
                    </BrowserRouter>
               </OidcProvider>
          </Provider>
     )
};
window.addEventListener('pageshow', (event) => {
     if(event.persisted) {
          sessionStorage.clear();
          window.location.reload();
     }else{
          setTimeout(() => {
               ReactDOM.render(<Init />, document.getElementById('root'))
          })
     }
});

serviceWorker.register();
