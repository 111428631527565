// React
import React from "react";

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Material
import MenuItem from '@material-ui/core/MenuItem';

// Form Wrappers
import { SelectWrapper } from "../../../components/form/select";
import FlagIcon from '../../../components/form/flagIcon';
import { Box, Collapse } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import FieldSkeleton from "../../../components/skeleton/FieldSkeleton";
import ct from "countries-and-timezones";


/**
 * @class Preferences
 * @description component contains Preferences Section
 */
class Preferences extends React.PureComponent {

     /**
      * @function constructor
      * @param {*} props - Having properties passed by parent element
      * @description used for state initialization
      */
     constructor(props) {
          super(props);
          this.state = {
               selectedTimeZoneId: null,
               timeZoneOptions: null
          };
     }

     /**
      * @function componentDidMount
      * @description called when Preferences section mounted 
      *  for refining timezone options based on selected country code 
      */
     componentDidMount() {
          this.filterTimeZones(this.props.countryCode, this.props);
     }

     UNSAFE_componentWillReceiveProps(newProps, newState) {
          if (newProps.countryCode !== this.props.countryCode || newProps.timeZones?.length !== this.props.timeZones?.length) {
               this.filterTimeZones(newProps.countryCode, newProps);
          }
     }
     /**
      * @function render
      * @description repaints the component on each change of state and props value
      */
     render() {
          let languageDropDown, countryDropDown, timeZoneDropDown;

          if (this.props.languages && this.props.firstName) {
               let languageOptions = [];
               languageOptions.push(<MenuItem value="" key="-1" disabled ></MenuItem >);
               this.props.languages.forEach((language, index) => {
                    languageOptions.push(<MenuItem value={language.code} key={index}>{language.name} {(language.name !== language.native) ? "(" + language.native + ")" : ""}</MenuItem>);
               });

               languageDropDown = (
                    <SelectWrapper
                         label={<FormattedMessage defaultMessage="Language" />}
                         id="language"
                         initialValue={this.props.languageCode}

                    >
                         {languageOptions}
                    </SelectWrapper >
               );
          }
          else {
               languageDropDown = <FieldSkeleton fieldWidth={36} />;
          }

          if (this.props.countries && this.props.firstName) {
               let countryOptions = [];
          
               countryOptions.push(<MenuItem value="" key="-1" disabled ></MenuItem >);
               this.props.countries.forEach((country, index) => {
                    if (!this.props.embargoed_countries.has(country.name)) {
                         countryOptions.push(<MenuItem value={country.alpha2_code ? country.alpha2_code : country.alpha3_code} key={index}>
                              <Box display="flex" alignItems="center">
                                   <Box>
                                        <FlagIcon name={country.alpha2_code} />
                                   </Box>
                                   <Box paddingLeft="16px">
                                        {country.name} {(country.name !== country.native) ? "(" + country.native + ")" : ""}
                                   </Box>
                              </Box>
                         </MenuItem>);
                    }

               });
               countryDropDown = (
                    <SelectWrapper
                         label={<FormattedMessage defaultMessage="Country or Region" />}
                         id="country"
                         initialValue={this.props.countryCode}
                         onChange={this.filterTimeZones}
                    >
                         {countryOptions}
                    </SelectWrapper >
               );
          } else {
               countryDropDown = <FieldSkeleton fieldWidth={36} />;
          }
          if (this.props.timeZones && this.props.firstName) {
               timeZoneDropDown = (
                    <SelectWrapper
                         label={<FormattedMessage defaultMessage="Time Zone" />}
                         id="timezone"
                         initialValue={this.props.timezone}
                    >
                         {this.state.timeZoneOptions}
                    </SelectWrapper >
               )

          } else {
               timeZoneDropDown = <FieldSkeleton fieldWidth={36} />;
          }



          return (
               <div className="card preferences" >
                    <h3>
                         <FormattedMessage defaultMessage="Preferences" />
                    </h3>
                    <div className="form-holder">
                         {countryDropDown}
                         {languageDropDown}
                         <Collapse in={(this.props.countryCode || window.formHooks.getValues()?.country) ? true : false}>
                              {timeZoneDropDown}
                         </Collapse>
                    </div>
               </div>
          );
     }

     filterTimeZones = (countryCode, props = this.props) => {
          if (!props.timeZones || !props.countries) return;

          const timezoneMap = new Map();

          const country = ct.getCountry(countryCode) || {};

          (country.timezones || [])
               .forEach(val => {
                    const zoneInfo = props.timeZones.find(zone => zone.name === val);

                    if (zoneInfo) {
                         timezoneMap.set(zoneInfo.code, zoneInfo)
                    } else {
                         const subZoneInfo = props.timeZones.find(zone => {
                              const zones = (zone.name || "").split(" ");

                              return zones.some(value => value === val)
                         });

                         if (subZoneInfo) {
                              timezoneMap.set(subZoneInfo.code, subZoneInfo)
                         }
                    }
               });

          const filteredTimezones = Array.from(timezoneMap.values());

          let timeZoneOptions = [];
          timeZoneOptions.push(<MenuItem value="" key="-1" disabled></MenuItem >);
          let isDefaultPicked = false;
          let changeDefaultValue = null;

          filteredTimezones.forEach((timeZone, index) => {
               const zoneTime = (timeZone.zoneTime || "").split(" ")[0];

               timeZoneOptions.push(<MenuItem value={timeZone.code} key={index}>{`${zoneTime}${zoneTime ? " " : ""}${timeZone.description}`}</MenuItem>);

               if (!isDefaultPicked) {
                    changeDefaultValue = timeZone.code;
                    isDefaultPicked = true;
               }
          });

          if (timeZoneOptions.length <= 1 || !country) {
               props.timeZones.forEach((timeZone, index) => {
                    const zoneTime = (timeZone.zoneTime || "").split(" ")[0];

                    if (!isDefaultPicked) {
                         changeDefaultValue = timeZone.code;
                         isDefaultPicked = true;
                    }
                    timeZoneOptions.push(<MenuItem value={timeZone.code} key={index}>{`${zoneTime}${zoneTime ? " " : ""}${timeZone.description}`}</MenuItem>);
               });
          }

          this.setState({ timeZoneOptions: timeZoneOptions });
          if (window.formHooks.getValues()?.country && window.formHooks.getValues()?.country === props.countryCode) {
               changeDefaultValue = props.timezone;
          }
          window.formHooks.setValue('timezone', changeDefaultValue);
     }
}


/**
 * For mapping necessary state variables to current component properties
 * @param {Object} state - Immutable State Object
 * @return properties needed from state 
 */
const mapStateToProps = (state) => {
     const embargoed_countries = new Set((state.common.embargoed_countries ?? []).map(value => value.name));
     const embargoed_country_codes = new Set((state.common.embargoed_countries ?? []).map(value => value.alpha2_code ? value.alpha2_code : value.alpha3_code));
     const country = state.userManager?.country;

     return {
          countries: state.common.countries,
          languages: state.common.languages,
          timeZones: state.common.timeZones,
          languageCode: state.userManager?.language,
          countryCode: country ? embargoed_country_codes.has(country) ? undefined : country : undefined,
          timezone: state.userManager?.timezone,
          firstName: state.userManager?.firstName,
          embargoed_countries,
     }
};
export default withRouter(connect(mapStateToProps)(Preferences));