import axios from 'axios';

/**
 * @class IdentityAPI
 * @description For API Detail reference and replication
 *  * setting basic headers for all APIs
 */
export class IdentityAPI {
     static get_my_details = "/user/{uuid}";

     /**
      * @function parseRequestURL
      * @description to replace the value to be passed to above string variables
      * @example /user/{token}/create - /user/123/create
      * @param {*} requestURL 
      * @param {*} request 
      */
     static parseRequestURL = (requestURL, request) => {
          Object.keys(request).forEach(key => {
               requestURL = requestURL.replace(new RegExp('{' + key + '}', 'g'), request[key]);
          });
          return requestURL;
     }
}

const IdentityService = axios.create();
IdentityService.defaults.baseURL = process.env.REACT_APP_IDENTITY_API_URL;
IdentityService.defaults.headers.post['Content-Type'] = 'application/json';

IdentityService.setToken = (accessToken) => {
     IdentityService.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
};

export default IdentityService;