import React from "react";
import PropTypes from "prop-types";

/**
 * @class Loader
 * @description component contains loading image which appears on Backend Calls and processing.
 */
class Loader extends React.PureComponent {

	/**
	 * @function constructor
	 * @param {*} props - Having properties passed by parent element
	 *  * used for state initialization
	 */
     constructor(props) {
          super(props);
          this.state = {}
     }


     /**
      * @function render
      * @description repaints the component on each change of state and props value
      */
     render() {
          if (this.props.showBackdrop) {
               return (
                    <div className="loader backdrop">
                         <div className="loader-container">
                              <div className="loader-circle">
                                   <div></div>
                                   <div></div>
                                   <div></div>
                              </div>
                         </div>
                    </div>
               )
          }

          return (
               <div className="loader">
                    <div className="loader-circle">
                         <div></div>
                         <div></div>
                         <div></div>
                    </div>
               </div>
          )
     }
}

/**
 * Properties to be received from Parent component for initializing this component
 */
Loader.propTypes = {
     showBackdrop: PropTypes.bool
};

/**
 * Default property value if component nto passign the value
 */
Loader.defaultProps = {
     showBackdrop: false
};

export default Loader;