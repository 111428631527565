// React
import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';

const FieldSkeleton = (props) => {
     return (
          <div style={{ width: "100%" }}>
               <Skeleton animation="wave" variant="rect" width="25%" height={14} />
               <div style={{ marginTop: "6px" }}>
                    <Skeleton animation="wave" variant="rect" width="70%" height={props.fieldWidth ?? 24} />
               </div>
          </div>
     );
};

export default FieldSkeleton;