import React from "react";
import PropTypes from "prop-types";

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import { Controller } from 'react-hook-form';
import { Validations, noSpaceValidator } from "./validations";
import classNames from 'classnames';

/**
 * @component TextFieldWrapper
 * @param {*} props 
 */
export const TextFieldWrapper = (props) => {

     if (window.formHooks.errors[props.id]) {
          switch (window.formHooks.errors[props.id].type) {
               case Validations.required:
                    window.formHooks.errors[props.id].message = props.validations.required.message;
                    break;
               case Validations.pattern:
                    window.formHooks.errors[props.id].message = props.validations.pattern.message;
                    break;
               case Validations.noSpace:
                    window.formHooks.errors[props.id].message = props.validations.noSpace.message;
                    break;
               default:
                    break;
          }
     }
     return (
          <FormControl>
               <label className={
                    classNames({
                         "label": true,
                         "required": (props.validations?.required) ? true : false
                    })
               }>
                    {props.label}

               </label>
               <Controller

                    control={window.formHooks.control}
                    name={props.id}
                    defaultValue={props.initialValue}
                    rules={
                         {
                              required: (props.validations?.required) ? true : false,
                              pattern: (props.validations?.pattern) ? props.validations.pattern.regex : false,
                              validate: {
                                   noSpace: (props.validations?.noSpace) ? noSpaceValidator : undefined,
                              }
                         }
                    }

                    render={
                         (controller) => (
                              <TextField
                                   type={props.type}
                                   name={controller.name}
                                   defaultValue={controller.value}
                                   disabled={props.disabled}
                                   placeholder={props.placeHolder}
                                   onChange={(event) => { controller.onChange(event.target.value); }}
                                   aria-invalid={(window.formHooks.errors?.[props.id]) ? true : false}
                                   aria-label={window.formHooks.formState.isDirty && props.initialValue !== controller.value ? "touched" : ""}
                              />
                         )
                    }
               />
               {window.formHooks.errors[props.id] && <div className="element-level-error">{window.formHooks.errors[props.id].message}</div>}
          </FormControl>
     );
}

TextFieldWrapper.propTypes = {
     label: PropTypes.any.isRequired,
     type: PropTypes.string,
     id: PropTypes.string.isRequired,
     initialValue: PropTypes.string,
     placeHolder: PropTypes.string,
     required: PropTypes.bool,
     value: PropTypes.string,
     requiredErrorMessage: PropTypes.string,
     helperMessage: PropTypes.string,
     disabled: PropTypes.bool,
     onChange: PropTypes.func,
     pattern: PropTypes.any
};

TextFieldWrapper.defaultProps = {
     label: null,
     type: "text",
     id: "text",
     initialValue: "",
     placeHolder: "Enter the value",
     required: false,
     value: "",
     requiredErrorMessage: "This field is required",
     helperMessage: "",
     disabled: false,
     onChange: null,
     pattern: null
};
