import { createStore, compose, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import { loadUser } from "redux-oidc";
import authenticationManager from "../utils/authenticationManager";
import { routerMiddleware, browserHistory } from "react-router-redux";
import { composeWithDevTools } from 'redux-devtools-extension';

import { syncHistoryWithStore } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { loadState } from "./sessionStorage";


/**
 * Logger Middleware - To log the set of Actions and Payload
 * @param {Object} store 
 */
const loggerMiddleware = store => next => action => {
     if (action) {
          next(action);
     }
};


/**
 * Dev tools Initialization for Local testing 
 * Logger 
 * Updating Browser History in store
 */

const persistedState = loadState();

const createStoreWithMiddleware = compose(
     composeWithDevTools(applyMiddleware(loggerMiddleware, routerMiddleware(browserHistory)))
)(createStore);

const store = createStoreWithMiddleware(rootReducer, persistedState);

loadUser(store, authenticationManager);

const testbrowserHistory = createBrowserHistory();
export const history = syncHistoryWithStore(testbrowserHistory, store);


export default store;