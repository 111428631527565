
/**
 * To modify immutable objects
 * @param {Object} oldObject - Immutable state
 * @param {Object} updatedProperties - Modified properties
 * @return Modified State
 */
export const updateObject = (oldObject, updatedProperties) => {
     return {
          ...oldObject,
          ...updatedProperties
     };
};


/**
 * To Check whether the Object is Valid or not
 * @param {Object} Object of any type - Immutable state
 * @return boolean true if null or undefined
 */
export const isValid = (object) => {
     return (object !== null && object !== undefined);
}