// React Dependencies
import React from 'react';

// Redux Dependencies
import * as actions from '../../store/actions/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Material Dependencies
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { FormattedMessage } from "react-intl";
import { TrimbleIcon } from './TrimbleLogo';


/**
 * @component Header
 * @description - contains horizontal navigation section with user panel
 */
class Header extends React.PureComponent {

     // Class variables
     constructor(props) {
          super(props);
          this.state = {
               anchorEl: null,
               logoStyle: { width: "119px", height: "100%" },
     
          }
     }

     render() {
          return (
               <AppBar position="static">
                    <Toolbar>
                         <div className="company-branding">
                              <TrimbleIcon style={this.state.logoStyle} />
                         </div>
                         <Typography variant="h6" className="application-name">
                              <FormattedMessage defaultMessage="My Profile" />
                         </Typography>
                         <div className="user-panel">
                              <IconButton
                                   aria-label="account of current user"
                                   aria-controls="menu-appbar"
                                   aria-haspopup="true"
                                   onClick={this.handleMenu}
                                   color="inherit"
                                   disabled={!this.props.accessToken}
                              >
                                   <AccountCircleOutlinedIcon fontSize="large" />
                              </IconButton>
                              <Menu
                                   id="menu-appbar"
                                   anchorEl={this.state.anchorEl}
                                   anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                   }}
                                   keepMounted
                                   transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                   }}
                                   open={Boolean(this.state.anchorEl)}
                                   onClose={this.onClose}
                              >
                                   <MenuItem onClick={this.signout}><FormattedMessage defaultMessage="Sign Out" /></MenuItem>
                              </Menu>
                         </div>
                    </Toolbar>
               </AppBar>

          );
     }

     /**
      * @function handleMenu
      * @description Toggles User panel
      * @param {*} event 
      */
     handleMenu = (event) => {
          this.setState({ anchorEl: event.currentTarget });
     };

     /**
      * @function onClose
      * @description remove anchorElement on closing user panel
      */
     onClose = () => {
          this.setState({ anchorEl: null });
     };

     /**@function signout
      * @description calls state management to save and signout.
      */

     signout = () => {
          this.onClose();
          this.props.dispatch({ type: actions.signOut });
     }
}
const mapStateToProps = (state) => {
     return {
          accessToken: state.auth.access_token
     }
};

export default withRouter(connect(mapStateToProps)(Header));