import { createUserManager } from 'redux-oidc';

const configuration = {
     client_id: process.env.REACT_APP_AUTHENTICATION_KEY,
     redirect_uri: process.env.REACT_APP_AUTHENTICATION_CALLBACK_URL,
     response_type: 'code',
     scope: 'openid ' + process.env.REACT_APP_APPLICATION_NAME,
     authority: process.env.REACT_APP_AUTHENTICATION_URL,
     loadUserInfo: false,
     post_logout_redirect_uri: process.env.REACT_APP_LOGOUT_CALLBACK_URL,
     revokeAccessTokenOnSignout: false,
     automaticSilentRenew: true,
};

const authenticationManager = createUserManager(configuration);

export default authenticationManager;