let INITIAL_STATE = {}
export const loadState = () => {
     try {
          const serializedState = sessionStorage.getItem('state');

          if (serializedState === null) {
               return INITIAL_STATE;
          }
          return INITIAL_STATE;
          // return JSON.parse(serializedState);
     } catch (error) {
          return INITIAL_STATE;
     }
};

export const saveState = (state) => {
     try {
          const serializedState = JSON.stringify(state);
          sessionStorage.setItem('state', serializedState);
     } catch (error) {
          // Ignore write errors.
     }
};