// React
import React from "react";

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

import MuiAlert from '@material-ui/lab/Alert';

import Slide from '@material-ui/core/Slide';
import * as actions from '../../../../store/actions/actions';

import ReactCrop from 'react-image-crop';

// Service
import ProfileService, { UserProfileAPI } from '../../../../services/profileService';
import { Collapse } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { injectIntl } from 'react-intl';


const Transition = React.forwardRef(function Transition(props, ref) {
     return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * @class Basics
 * @description component contains Basic Section
 */
class PhotoEditor extends React.PureComponent {

     constructor(props) {
          super(props);
          this.state = {
               isShowCropper: this.props.photo ? true : false,
               croppedImageRef: null,
               cropOptions: {
                    unit: '%', // default, can be 'px' or '%'
                    aspect: 1,
                    x: 0, y: 0, width: 100, height: 100
               }
          };
     }
     UNSAFE_componentWillReceiveProps(newProps) {
          if (newProps.photo) {
               this.setState({
                    isShowCropper: true,
                    cropOptions: {
                         unit: '%', // default, can be 'px' or '%'
                         aspect: 1,
                         x: 0, y: 0, width: 100, height: 100
                    }
               });
          }
     }

     render() {
          return (
               <Dialog
                    open={this.state.isShowCropper}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.onClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
               >
                    <h3><FormattedMessage defaultMessage="Crop Profile Photo" /></h3>
                    <DialogContent>
                         <Collapse in={this.state.message === "" ? false : true}>
                              <MuiAlert
                                   variant="filled"
                                   severity={this.state.messageType}
                              >{this.state.message}</MuiAlert>
                         </Collapse>
                         <div className="flex-row-center">
                              <ReactCrop
                                   src={this.props.photo}
                                   crop={this.state.cropOptions}
                                   ruleOfThirds
                                   circularCrop={true}
                                   onImageLoaded={this.onCropperLoaded}
                                   onComplete={this.onCropComplete}
                                   onChange={cropParams => this.setState({ cropOptions: cropParams })}

                              />
                              <div className="preview-holder">
                                   <img
                                        src={this.state.croppedImageUrl}
                                        className="preview" loading="lazy" alt="user" />
                                   <div className="pixels">{this.state.croppedWidth} x {this.state.croppedHeight}</div>
                              </div>
                         </div>
                    </DialogContent>
                    <DialogActions>
                         <Button
                              type="button"
                              variant="contained"
                              size="large"
                              disableElevation
                              className="buttonLink"
                              onClick={this.onClose}
                         >                              
                              <FormattedMessage defaultMessage="Cancel" />
                              </Button>
                         <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              size="large"
                              disableElevation
                              disabled={this.state.message !== ""}
                              onClick={this.savePhoto}
                         >
                              <FormattedMessage defaultMessage="Save Photo" />
                              </Button>
                    </DialogActions>
               </Dialog>
          );
     }

     onClose = () => {
          this.setState({ isShowCropper: false });
          this.props.onClose();
     }

     onCropperLoaded = image => {
          this.setState({ croppedImageRef: image });
          if (this.state.cropOptions.unit === '%') {
               var crop = {
                    unit: '%', // default, can be 'px' or '%'
                    aspect: 1,
                    x: 0, y: 0, width: image.width, height: image.height
               };
               this.setState({ cropOptions: crop });
               this.createCroppedImage(crop, image);
          } else {
               this.createCroppedImage(this.state.cropOptions, image);
          }
     };

     onCropComplete = (crop) => {
          if (crop.unit === '%') {
               crop.unit = "px";
               crop.width = this.state.croppedImageRef.width;
               crop.height = this.state.croppedImageRef.height;
          }
          this.createCroppedImage(crop, this.state.croppedImageRef);
     };

     async createCroppedImage(crop, image) {
          if (image && crop.width && crop.height) {
               const croppedImageUrl = await this.getCroppedImg(
                    image,
                    crop,
                    'preview.png'
               );
               this.setState({ croppedImageUrl: croppedImageUrl });
          }
     }

     getCroppedImg = (image, crop, fileName) => {
          const canvas = document.createElement("canvas");
          var scaleX = image.naturalWidth / image.width;
          var scaleY = image.naturalHeight / image.height;
          var originWidth = crop.width * scaleX;
          var originHeight = crop.height * scaleY;

          var maxWidth = 1200, maxHeight = 1200 / (16 / 9);
          var targetWidth = originWidth,
               targetHeight = originHeight;
          if (originWidth > maxWidth || originHeight > maxHeight) {
               if (originWidth / originHeight > maxWidth / maxHeight) {
                    targetWidth = maxWidth;
                    targetHeight = Math.round(maxWidth * (originHeight / originWidth));
               } else {
                    targetHeight = maxHeight;
                    targetWidth = Math.round(maxHeight * (originWidth / originHeight));
               }
          }
          var minWidth = 320, minHeight = 240;
          if (originWidth < minWidth || originHeight < minHeight) {
               this.setState({ message: this.props.intl.formatMessage({
                    defaultMessage: "Photo should be greater than 320px x 320px"
               }), messageType: "warning" });
          } else {
               this.setState({ message: "" });
          }
          this.setState({ croppedWidth: Math.ceil(originWidth), croppedHeight: Math.ceil(originHeight) });

          // set canvas size
          canvas.width = targetWidth;
          canvas.height = targetHeight;
          const ctx = canvas.getContext("2d");

          ctx.drawImage(
               image,
               crop.x * scaleX,
               crop.y * scaleY,
               crop.width * scaleX,
               crop.height * scaleY,
               0,
               0,
               targetWidth,
               targetHeight
          );

          return new Promise((resolve, reject) => {
               canvas.toBlob(
                    blob => {
                         if (!blob) {
                              console.error("Canvas is empty");
                              return;
                         }
                         blob.name = fileName;

                         window.URL.revokeObjectURL(this.fileUrl);
                         this.fileUrl = window.URL.createObjectURL(blob);
                         this.setState({ 'blob': blob });
                         resolve(this.fileUrl);
                    },
                    "image/png",
                    1
               );
          });
     }

     savePhoto = () => {
          let apiCall = UserProfileAPI.get_my_photo;
          let request = {
               userId: this.props.user.uuid
          };
          let payload = new FormData();
          //payload.append("file", new Blob([this.state.croppedImageUrl], { type: "image/png" }));
          payload.append("file", this.state.blob);
          payload.append("imageType", "avatar");

          this.setState({ saveClicked: true, message: this.props.intl.formatMessage({
               defaultMessage: "Saving..."
          }), messageType: "info" });

          const imageRequest = this.props.user["picture"] ? ProfileService.put(
               UserProfileAPI.parseRequestURL(apiCall, request),
               payload
          ) : ProfileService.post(
               UserProfileAPI.parseRequestURL(apiCall, request),
               payload
          );  

          imageRequest.then(
               (response) => {
                    this.setState({ message: this.props.intl.formatMessage({
                         defaultMessage: "Changes Saved successfully..."
                    }), messageType: "success" });
                    this.props.dispatch(
                         {
                              type: actions.storeUserDetails,
                              payload: {
                                   picture: response.data.picture
                              }
                         }
                    );
               }
          ).catch(
               (error) => {

                    this.setState({ message: error.response.data.message, messageType: "error" });
               }
          );
     }

}

const mapStateToProps = (state) => {
     return {
          user: state.userManager
     }
};

export default withRouter(connect(mapStateToProps)(injectIntl(PhotoEditor)));