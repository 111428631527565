// React
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
     createStyles({
          img: {
               width: '100%',
          },
          small: {
               width: "24px",
               height: "16px"
          }
     }),
);

const FlagIcon = (props) => {
     const [flagUrl, setFlagUrl] = useState(null);
     const styles = useStyles();

     useEffect(() => {
          if (props.name) {
               import(/* webpackMode: "eager" */`svg-country-flags/svg/${props.name.toLowerCase()}.svg`).then(srcModule => window.requestAnimationFrame(() => setFlagUrl(srcModule.default))).catch(() => { })
          }

     }, [props.name]);

     return (
          <div className={styles.small}>
               <img loading="lazy" alt={props.name} className={styles.img} src={flagUrl} />
          </div>
     )
};

export default FlagIcon;