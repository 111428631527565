
/**
 * @file actions
 * @description contains list of all actions in redux state
 */
export const authSuccess = 'AUTH_SUCCESS';
export const loadUserData = 'LOAD_USER_DATA';
export const signOut = 'SIGN_OUT';
export const storeUserDetails = 'STORE_USER_DETAILS';
export const storeIdentityDetails = 'STORE_IDENTITY_DETAILS';
export const clearUserDetails = 'CLEAR_USER_DETAILS';
export const storeBasics = 'STORE_BASICS';